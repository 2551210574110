<template>
	<BaseEditControls
		:block-id="blockId"
		:block-ref="blockRef"
		:button-title="$t('builder.editImage.title')"
	>
		<ZyroPopupCard
			type="editor"
			:title="$t('builder.editImage.popupTitle')"
			:tabs="$options.TABS"
			:current-tab="currentTab"
			@change="currentTab = $event"
			@close="$emit('close')"
		>
			<Component :is="currentTab.component" />
		</ZyroPopupCard>
	</BaseEditControls>
</template>
<script>
import BaseEditControls from '@/components/builder-controls/BaseEditControls.vue';
import i18n from '@/i18n/setup';

import EditImageTabAction from './EditImageTabAction.vue';
import EditImageTabImage from './EditImageTabImage.vue';
import EditImageTabSeo from './EditImageTabSeo.vue';

const TABS = [
	{
		title: i18n.t('common.image'),
		component: 'EditImageTabImage',
	},
	{
		title: i18n.t('common.action'),
		component: 'EditImageTabAction',
	},
	{
		title: i18n.t('common.seo'),
		component: 'EditImageTabSeo',
	},
];

export default {
	components: {
		BaseEditControls,
		EditImageTabImage,
		EditImageTabSeo,
		EditImageTabAction,
	},
	props: {
		blockId: {
			type: String,
			required: true,
		},
		blockRef: {
			type: HTMLElement,
			required: true,
		},
	},
	data() {
		return { currentTab: TABS[0] };
	},
	TABS,
};
</script>
