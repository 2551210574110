<template>
	<div>
		<ZyroDropdown
			:options="IMAGE_CLICK_ACTIONS"
			:current="currentImageClickAction"
			:label="$t('builder.imageClickAction.title')"
			button-size="large"
			@update:current="setImageClickAction"
			v-on="$listeners"
		/>
		<LinkSettings
			v-if="isImageClickActionLink"
			:label="$t('builder.editButton.tabActionLabel')"
			:base-target="currentElement.settings.target || '_self'"
			:base-href="currentElement.settings.href"
			no-content
			@settings-update="linkSettingsData = {
				...linkSettingsData,
				...$event
			}"
		/>
	</div>
</template>

<script>
import {
	mapState,
	mapGetters,
	mapMutations,
} from 'vuex';

// TODO: Make link settings global everywhere
import {
	IMAGE_CLICK_ACTION_NONE,
	IMAGE_CLICK_ACTION_NONE_TITLE,
	IMAGE_CLICK_ACTION_LIGHTBOX,
	IMAGE_CLICK_ACTION_LIGHTBOX_TITLE,
	IMAGE_CLICK_ACTION_LINK,
	IMAGE_CLICK_ACTION_LINK_TITLE,
} from '@/components/builder-controls/constants';
import LinkSettings from '@/components/builder-controls/edit-button/LinkSettings.vue';
import { cloneDeep } from '@/utils/object';

const DEFAULT_IMAGE_ACTION_INDEX = 0;

const IMAGE_CLICK_ACTIONS = [
	{
		title: IMAGE_CLICK_ACTION_NONE_TITLE,
		value: IMAGE_CLICK_ACTION_NONE,
	},
	{
		title: IMAGE_CLICK_ACTION_LIGHTBOX_TITLE,
		value: IMAGE_CLICK_ACTION_LIGHTBOX,
	},
	{
		title: IMAGE_CLICK_ACTION_LINK_TITLE,
		value: IMAGE_CLICK_ACTION_LINK,
	},
];

export default {
	components: { LinkSettings },
	setup() {
		return { IMAGE_CLICK_ACTIONS };
	},
	data() {
		return {
			imageAction: IMAGE_CLICK_ACTIONS[DEFAULT_IMAGE_ACTION_INDEX],
			elementId: null,
			currentElementBeforeEdit: null,
			linkSettingsData: {
				href: '',
				isUrlValid: true,
				target: '',
				content: '',
			},
		};
	},
	computed: {
		...mapState(['currentElementId']),
		...mapGetters(['currentElement']),
		currentImageClickAction() {
			return IMAGE_CLICK_ACTIONS
				.find((action) => action.value === this.currentElement.settings.clickAction);
		},
		isImageClickActionLink() {
			return this.imageAction.value === IMAGE_CLICK_ACTION_LINK;
		},
	},
	watch: {
		'linkSettingsData.content': function updateContent(newValue) {
			this.setElementData({ data: { content: newValue } });
		},
	},
	beforeMount() {
		this.elementId = this.currentElementId;
		this.currentElementBeforeEdit = cloneDeep(this.currentElement);

		/**
		 * If user opens action tab for the first time lightbox will be auto selected,
		 * Current user images shouldn't be affected so this is not solvable by a mapper
		 */
		if (this.currentImageClickAction === undefined) {
			this.setImageClickAction(this.getImageAction(IMAGE_CLICK_ACTION_LIGHTBOX));
		}
	},
	beforeDestroy() {
		const {
			href,
			target,
			isUrlValid,
		} = this.linkSettingsData;

		const parameters = { elementId: this.elementId };

		if (this.isImageClickActionLink) {
			parameters.data = isUrlValid ? {
				settings: {
					href,
					target,
				},
			} : this.currentElementBeforeEdit;
		} else {
			parameters.data = { settings: { href: '' } };
		}

		this.setElementData(parameters);
		this.pushElementDataToHistory(
			{
				elementId: this.elementId,
				oldData: this.currentElementBeforeEdit,
			},
		);
	},
	methods: {
		...mapMutations([
			'pushElementDataToHistory',
			'setElementData',
		]),
		setImageClickAction(action) {
			this.imageAction = action;
			this.setElementData({ data: { settings: { clickAction: action.value } } });
		},
		getImageAction(action) {
			return IMAGE_CLICK_ACTIONS.find((actionSet) => actionSet.value === action);
		},
	},
};
</script>
