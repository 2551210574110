<template>
	<div>
		<ZyroImageSelector
			:value="image"
			:object-fit="objectFit"
			@input="image = $event"
		/>
		<ZyroFieldToggle
			id="object-fit-toggle"
			v-model="objectFit"
			:checked="objectFit === 'cover'"
			:label="$t('builder.editImage.tabImage.label')"
			@change="objectFit = $event"
		/>
	</div>
</template>

<script>
import {
	mapGetters,
	mapState,
	mapMutations,
} from 'vuex';

import { cloneDeep } from '@/utils/object';

export default {
	data() {
		return {
			id: '',
			currentElementBeforeEdit: null,
		};
	},
	computed: {
		...mapState(['currentElementId']),
		...mapGetters(['currentElement']),
		objectFit: {
			get() {
				return this.currentElement.settings.styles['object-fit'] || 'cover';
			},
			set(event) {
				this.setElementData({ data: { settings: { styles: { 'object-fit': event.target.checked ? 'cover' : 'contain' } } } });
			},
		},
		image: {
			get() {
				return this.currentElement.settings.image;
			},
			set(newValue) {
				this.setElementData({
					data: {
						settings: {
							image: newValue.url,
							alt: newValue.alt,
						},
					},
				});
			},
		},
	},
	mounted() {
		this.id = this.currentElementId;
		this.currentElementBeforeEdit = cloneDeep(this.currentElement);
	},
	beforeDestroy() {
		this.pushElementDataToHistory({
			elementId: this.id,
			oldData: this.currentElementBeforeEdit,
		});
	},
	methods: mapMutations([
		'pushElementDataToHistory',
		'setElementData',
	]),
};
</script>
