<template>
	<div class="edit-image-tab-seo">
		<ZyroFieldText
			:id="`${currentElementId}alt`"
			v-model="alt"
			maxlength="100"
			:label="$t('builder.editImage.tabSeo.textFieldLabel')"
		/>
		<ZyroLabel
			class="edit-image-tab-seo__label z-body-small"
			:bold="false"
		>
			{{ $t('builder.editImage.tabSeo.label') }}
		</ZyroLabel>
	</div>
</template>

<script>
import {
	mapMutations,
	mapState,
	mapGetters,
} from 'vuex';

export default {
	data() {
		return {
			altTemp: '',
			id: '',
		};
	},
	computed: {
		...mapState([
			'currentElementRef',
			'currentElementId',
		]),
		...mapGetters(['currentElement']),
		alt: {
			set(event) {
				this.altTemp = event.target.value;
				this.currentElementRef.$el.querySelector('img').alt = event.target.value;
			},
			get() {
				return this.currentElement.settings.alt;
			},
		},
	},
	mounted() {
		this.altTemp = this.currentElement.settings.alt;
		this.id = this.currentElementId;
	},
	beforeDestroy() {
		this.setElementData({
			elementId: this.id,
			data: { settings: { alt: this.altTemp } },
			skipHistory: false,
		});
	},
	methods: { ...mapMutations(['setElementData']) },
};
</script>
<style lang="scss" scoped>
.edit-image-tab-seo {
	&__label {
		color: $grey-800;
	}

	::v-deep .zyro-field-text {
		margin-bottom: 8px;
	}
}
</style>
